<template>
  <div class="app-item">
    <div class="app-content">
      <div class="top-title">
        <h4>创建内容：</h4>
      </div>
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="作者" prop="author">
          <el-input v-model="ruleForm.author" placeholder="请输入作者"></el-input>
        </el-form-item>
        <el-form-item label="文章标签" prop="region">
          <!-- <el-cascader
            :change-on-select="true"
            :options="tag"
            :props="defaultParams"
            v-model="selectedOptions"
            :show-all-levels="true"
          ></el-cascader> -->
            <el-select v-model="ruleForm.tag_id" placeholder="请选择">
            <el-option
              v-for="(item,ind) in tag"
              :key="ind"
              :label="item.tag_name"
              :value="item.id"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="摘要" prop="digest">
          <el-input v-model="ruleForm.digest" placeholder="请输入摘要内容"></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="licenseImg" class="upload-img">
          <el-upload
            class="avatar-uploader"
            action=" "
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <div class="upload-img">
              <img v-if="ruleForm.cover" :src="ruleForm.cover" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="价格" prop="title">
          <el-input placeholder="请输入价格" v-model="ruleForm.price">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="VIP价格">
          <el-input placeholder="请输入价格" v-model="ruleForm.vip_price">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="文章类型" prop="resource">
          <el-radio-group v-model="ruleForm.type">
            <el-radio label="1">文档</el-radio>
            <el-radio label="2">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文件/视频" prop="resource" class="publish-item">
          <el-upload
            class="upload-demo"
            action=" "
            v-loading="loading"
            :before-remove="beforeRemove"
            :http-request="fnUploadRequest"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :accept="accept"
          >
            <el-button size="small" type="primary" @click="showdetail=false">点击上传</el-button>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-upload>
           <a :href="ruleForm.sign_url_preview" class="show_detail" v-if="showdetail">查看详情</a>
        </el-form-item>
        <el-form-item label="文章权限" prop="resource">
          <el-radio-group v-model="ruleForm.public">
            <el-radio label="1">平台公开</el-radio>
            <el-radio label="2">公司公开</el-radio>
            <el-radio label="0">指定部门</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="部门" prop="region" v-if="ruleForm.public==='0'">
          <el-select
            v-model="ruleForm.department_ids"
            multiple
            collapse-tags
            style="margin-left: 20px;"
            placeholder="请选择"
          >
            <el-option v-for="(ele,ind) in options" :key="ind" :label="ele.name" :value="ele.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="showbtn">
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        </el-form-item>
        <el-form-item v-if="!showbtn">
          <el-button type="primary" @click="updateArticle('ruleForm')">立即修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// import OSS from 'ali-oss'
export default {
  data () {
    return {
      loading: false,
      showbtn: true,
      showdetail: false,
      // 标签列表
      tag: [],
      // 文章标签
      selectedOptions: [],
      // 文章标签所需参数
      defaultParams: {
        label: 'tag_name',
        value: 'id',
        children: 'children'
      },
      accept: '',
      // 商家信息
      store: '',
      // 表单信息
      ruleForm: {
        file: '',
        // 标题
        title: '',
        // 作者
        author: '',
        // 封面
        cover: '',
        //
        tag_id: null,
        // 摘要
        digest: '',
        path: '',
        // 文章类型
        type: '1',
        // 价格
        price: '',
        // vip价格
        vip_price: '',
        // 文章权限
        public: '1',
        // 部门
        department_ids: []
      },
      // 限制上传文件个数
      fileList: [],
      // 部门信息
      options: []
      // 部门选择
      // value11: []
    }
  },
  methods: {
    // 获取标签列表
    getTag () {
      this.$store.dispatch('getTag').then(res => {
        // console.log('标签列表:', res)
        this.tag = res
      })
    },
    // 上传封面
    beforeAvatarUpload (file) {
      // console.log(file)
      let fd = new FormData()
      fd.append('img', file)
      const isJPG = file.type === 'image/jpeg'
      this.$store.dispatch('uploadImg', fd).then(res => {
        this.ruleForm.cover = res.data.url
        this.ruleForm.path = res.data.path
      })
      return isJPG
    },
    // 上传文件/视频 阿里云
    fnUploadRequest (option) {
      this.loading = true
      this.$store.dispatch('getOss').then(res => {
        console.log('res:', res.data)
        let ossData = res.data
        let file = option.file
        const point = file.name.lastIndexOf('.')
        let fileName = file.name.substr(point)
        // console.log(fileName)
        let formData = new FormData()
        formData.append('key', ossData.key + fileName)
        formData.append('policy', ossData.policy)
        formData.append('OSSAccessKeyId', ossData.accessid)
        formData.append('success_action_status', 200)
        formData.append('signature', ossData.signature)
        formData.append('file', file)
        let params = {
          url: ossData.host,
          param: formData
        }
        this.$store.dispatch('putOss', params).then(res => {
          // console.log('oss:', res)
          if (res.status === 200) {
            this.ruleForm.file = ossData.key + fileName
            this.loading = false
            // console.log(this.key)
          }
        })
      })
    },
    // 限制上传文件个数
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    // 移除文件
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 获取部门名称
    getDepartments () {
      this.$store.dispatch('getDepartments').then(res => {
        // console.log('部门：', res)
        this.options = res
      })
    },
    // 提交
    submitForm (formName) {
      console.log(this.selectedOptions)
      if (this.key !== '') {
        let obj = {
          title: this.ruleForm.title,
          author: this.ruleForm.author,
          tag_id: this.ruleForm.tag_id,
          tag_children_id: this.selectedOptions[1] || 0,
          digest: this.ruleForm.digest,
          cover: this.ruleForm.path,
          type: this.ruleForm.type,
          file: this.ruleForm.file,
          public: this.ruleForm.public,
          price: this.ruleForm.price,
          vip_price: this.ruleForm.vip_price,
          department_ids: this.ruleForm.department_ids
        }
        console.log(obj)
        this.$store.dispatch('publishArticles', obj).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '发表文章成功',
              type: 'success'
            })
            this.$router.push({ name: 'UserHome' })
          } else {
            this.$message({
              message: res.message,
              type: 'success'
            })
          }
        })
      } else {
        this.$message({
          message: ' 请输入正确的信息',
          type: 'warning'
        })
      }
    },
    // 修改文章
    updateArticle (obj) {
      console.log(this.ruleForm)
      if (this.key !== '') {
        this.ruleForm.public = Number(this.ruleForm.public)
        this.ruleForm.tag_id = this.ruleForm.tag_id
        this.ruleForm.tag_children_id = 0
        this.ruleForm.file = this.ruleForm.file
        this.$store.dispatch('updateArticles', this.ruleForm).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'warning'
            })
            this.$router.push({ name: 'UserHome' })
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: ' 请输入正确的信息',
          type: 'warning'
        })
      }
    }
  },
  mounted () {
    this.getTag()
    this.getDepartments()
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.showbtn = !this.showbtn
      this.$store.dispatch('getStoreArticleDetail', this.$route.query.id).then(res => {
        // console.log(res)
        this.ruleForm = res
        this.ruleForm.type = String(this.ruleForm.type)
        this.ruleForm.public = String(this.ruleForm.public)
        this.selectedOptions.push(res.tag_id)
        this.selectedOptions.push(res.tag_children_id)
        if (this.ruleForm.sign_url_preview) {
          this.showdetail = true
        }
      })
    }
    // 判断是不是商家
    // this.$store.dispatch('getInfo').then(res => {
    //   console.log(res)
    //   this.store = res.info.store
    //   if (
    //     !(
    //       this.store &&
    //       this.store.is_administrator === 1 &&
    //       this.store.status > 0
    //     )
    //   ) {
    //     this.$message({
    //       showClose: true,
    //       message: '你还不是商家，请先申请入驻商家',
    //       type: 'error'
    //     })
    //     this.$router.push({ name: 'Tenants' })
    //   }
    // })
  }
}
</script>
<style lang="less" scoped>
.publish-item{
  /deep/ .el-form-item__content{
    display: flex
  }
  .show_detail{
    margin-left:50px;
    color:#3a8ee6
  }
}
</style>
